import React from 'react';

import Logo from '../logo';
import NoData from '../noData';
import Animate from '../animate';

import './index.scss';

const ErrorView = () => {
    return (
        <>
            <div className='error-view-holder'>
                <NoData
                    icon={{
                        name: 'bug',
                    }}
                    title='Somebody is getting fired for this!'
                    subTitle='Something went wrong, we are looking into it!'
                    primaryCTA={{
                        children: 'Refresh',
                        onClick: () => window.location.reload(),
                    }}
                />
            </div>
            <Animate
                fit
                animation='fadeIn'
            >
                <Logo fixed />
            </Animate>
        </>
    );
};

export default ErrorView;
