import { atom } from 'recoil';

export const userLocationState = atom({
    key: 'userLocationState',
    default: {
        latitude: 0,
        longitude: 0,
    },
});

export const tokenState = atom({
    key: 'tokenState',
    default: localStorage.getItem('token'),
});

export const splashLoadersSate = atom({
    key: 'splashLoadersSate',
    default: [],
});


export const splashSharedDataState = atom({
    key: 'splashSharedDataState',
    default: {
        name: '',
        author: '',
        source: '',
    },
});

export const proViewState = atom({
    key: 'proViewState',
    default: localStorage.getItem('token') ? !!localStorage.getItem('proView') : false,
});

export const socialShareState = atom({
    key: 'socialShareState',
    default: null,
});

export const usePaginatedDataState = atom({
    key: 'usePaginatedDataState',
    default: false,
});

export const globalDataState = atom({
    key: 'globalDataState',
    default: {
        pins: [],
        tags: [],
        tasks: [],
        users: [],
        routes: [],
        profile: {},
        loading: true,
        organization: {},
    },
});
