import PropTypes from 'prop-types';

import Icon from '../icon';
import Button from '../button';
import Animate from '../animate';

import './index.scss';

const NoData = props => {
    const {
        icon,
        title,
        subTitle,
        primaryCTA,
        description,
    } = props;

    return (
        <div className='no-data-holder'>
            <div className='no-data-content'>
                {!!icon && (
                    <div className='no-data-icon'>
                        <Animate>
                            <Icon
                                size={48}
                                {...icon}
                            />
                        </Animate>
                    </div>
                )}
                {(!!title || !!subTitle) && (
                    <div className='no-data-headings'>
                        {!!title && (
                            <Animate delay={100}>
                                <div className='no-data-title'>{title}</div>
                            </Animate>
                        )}
                        {!!subTitle && (
                            <Animate delay={200}>
                                <div className='no-data-sub-title'>{subTitle}</div>
                            </Animate>
                        )}
                    </div>
                )}
                {!!description && (
                    <Animate delay={300}>
                        <div className='no-data-description'>{description}</div>
                    </Animate>
                )}
                {!!primaryCTA && (
                    <div className='no-data-cta'>
                        <Animate delay={description ? 400 : 300}>
                            <Button
                                size='extra-large'
                                {...primaryCTA}
                            />
                        </Animate>
                    </div>
                )}
            </div>
        </div>
    );
};

NoData.propTypes = {
    icon: PropTypes.shape({
        size: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.oneOf([
            'line',
            'solid',
            'filled',
        ]),
        color: PropTypes.string,
        style: PropTypes.object,
        className: PropTypes.string,
    }),
    title: PropTypes.string,
    subTitle: PropTypes.string,
    primaryCTA: PropTypes.object,
    description: PropTypes.string,
};

export default NoData;
