import { conditionalSpread, rem } from 'clyne-core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import To from '../to';

import './index.scss';

const Logo = props => {
    const {
        fixed,
        onClick,
        size = 64,
        inset = 32,
    } = props;

    return (
        <To
            onClick={onClick}
            className={classNames(
                'logo-holder',
                {
                    fixed,
                }
            )}
            style={{
                width: rem(size),
                height: rem(size),
                ...conditionalSpread({
                    top: typeof inset === 'string' ? inset : rem(inset),
                    left: typeof inset === 'string' ? inset : rem(inset),
                }, fixed),
            }}
        >
            <svg
                fill='none'
                width={size}
                height={size}
                viewBox='0 0 64 64'
                xmlns='http://www.w3.org/2000/svg'
            >
                <rect width='64' height='64' rx='16' />
                <path fillRule='evenodd' clipRule='evenodd' d='M31.8934 13.6537C39.2572 13.6537 45.2267 19.6232 45.2267 26.987C45.2267 29.9114 44.2852 32.616 42.6888 34.814C41.0732 37.0385 31.8934 49.2092 31.8934 49.2092C31.8934 49.2092 22.7063 37.0326 21.0911 34.8045C19.4988 32.6082 18.5601 29.9072 18.5601 26.987C18.5601 19.6232 24.5296 13.6537 31.8934 13.6537ZM31.8934 22.5426C29.4388 22.5426 27.4489 24.5324 27.4489 26.987C27.4489 29.4416 29.4388 31.4315 31.8934 31.4315C34.348 31.4315 36.3378 29.4416 36.3378 26.987C36.3378 24.5324 34.348 22.5426 31.8934 22.5426Z' />
            </svg>
        </To>
    );
};

Logo.propTypes = {
    size: PropTypes.number,
    fixed: PropTypes.bool,
    inset: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onClick: PropTypes.func,
};

export default Logo;
