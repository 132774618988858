import TextTransition from 'react-text-transition';
import React, { forwardRef } from 'react';
import { toProps } from 'clyne-core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import To from '../to';
import Icon from '../icon';

import './index.scss';

const Button = forwardRef((props, ref) => {
    const {
        url,
        icon,
        delay,
        target,
        loading,
        onClick,
        disabled,
        children,
        animated,
        size = 'medium',
        appearance = 'fill',
        flexibility = 'fit',
    } = props;

    const content = (
        <>
            {!!icon && (
                <div
                    className={classNames(
                        'button-c-icon',
                        {
                            nm: !children,
                        }
                    )}
                >
                    {icon?.props ? icon : icon?.name ? (
                        <Icon
                            {...icon}
                        />
                    ) : (
                        <Icon
                            name={icon}
                        />
                    )}
                </div>
            )}
            {animated ? (
                <TextTransition
                    inline
                    delay={delay}
                >
                    {children}
                </TextTransition>
            ) : (
                <span>{children}</span>
            )}
            <span
                className={classNames(
                    'absolute-splash',
                    'inline-loader-holder',
                    {
                        loading,
                    }
                )}
            >
                <span className='inline-loader' />
            </span>
        </>
    );

    const sharedProps = {
        ref,
        onClick,
        className: classNames(
            'button-c',
            {
                loading,
                disabled,
                [`s-${size}`]: size,
                np: icon && !children,
                [`a-${appearance}`]: appearance,
                [`f-${flexibility}`]: flexibility,
            },
        ),
    };

    return url ? (
        <To
            url={url}
            target={target}
            {...sharedProps}
        >
            {content}
        </To>
    ) : (
        <button
            type='button'
            disabled={disabled}
            {...sharedProps}
        >
            {content}
        </button>
    );
});

Button.propTypes = {
    url: PropTypes.string,
    size: PropTypes.oneOf([
        'small',
        'medium',
        'large',
        'extra-large',
    ]),
    icon: PropTypes.any,
    delay: PropTypes.number,
    target: PropTypes.oneOf(toProps.target),
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.any,
    animated: PropTypes.bool,
    appearance: PropTypes.oneOf([
        'fill',
        'clear',
        'unset',
        'outline',
        'outline-hero',
        'outline-white',
        'outline-dashed',
    ]),
    flexibility: PropTypes.oneOf([
        'fit',
        'full',
    ]),
};

export default Button;
