import BugsnagPluginReact from '@bugsnag/plugin-react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import Bugsnag from '@bugsnag/js';
import axios from 'axios';

import Loader from './components/loader';
import ErrorView from './components/errorView';

import lazyWithRetry from './utils/lazyWithRetry';

import './assets/scss/main.scss';

import { API_URL } from './vars';

const StateHydrate = lazyWithRetry(() => import('./hydrators/stateHydrate'));
const RoutesHydrate = lazyWithRetry(() => import('./hydrators/routesHydrate'));
const ProvidersHydrate = lazyWithRetry(() => import('./hydrators/providersHydrate'));

axios.defaults.baseURL = API_URL;

Bugsnag.start({
    apiKey: '83d844d202be420baa3eb450407f5a18',
    plugins: [
        new BugsnagPluginReact(),
    ],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const root = createRoot(document.getElementById('root'));

root.render(
    <RecoilRoot>
        <BrowserRouter>
            <ErrorBoundary FallbackComponent={ErrorView}>
                <Suspense fallback={<Loader splash />}>
                    <ProvidersHydrate>
                        <StateHydrate>
                            <RoutesHydrate />
                        </StateHydrate>
                    </ProvidersHydrate>
                </Suspense>
            </ErrorBoundary>
        </BrowserRouter>
        <Loader
            main
        />
    </RecoilRoot>
);


