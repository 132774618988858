import { Lottie } from '@crello/react-lottie';
import { createPortal } from 'react-dom';
import { memo, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { splashLoadersSate } from '../../state';

import { guid } from '../../helpers';

import * as animationData from './media/lottie.json';

import './index.scss';

const uid = guid();

const Loader = memo(props => {
    const {
        main,
        splash,
        size = 300,
        active = true,
    } = props;

    const [splashLoaders, setSplashLoaders] = useRecoilState(splashLoadersSate);

    useEffect(() => {
        splash && setSplashLoaders(val => ([...val, uid]));

        return () => {
            splash && setSplashLoaders(val => val.filter(id => id !== uid));
        };
    }, []);

    const play = main ? !!splashLoaders.length : active;

    const loaderRenderer = (
        <div
            className={classNames(
                'loader-holder',
                {
                    main,
                    splash,
                    active: play,
                }
            )}
        >
            {!splash && (
                <div className='lottie-loader'>
                    <Lottie
                        width={size}
                        height={size}
                        playingState={play ? 'playing' : 'paused'}
                        config={{
                            loop: true,
                            autoplay: true,
                            animationData: animationData,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            },
                        }}
                    />
                </div>
            )}
        </div>
    )

    return main ? createPortal(loaderRenderer, document.body) : loaderRenderer;
});

Loader.propTypes = {
    size: PropTypes.number,
    main: PropTypes.bool,
    active: PropTypes.bool,
    splash: PropTypes.bool,
};

export default Loader;
