import { useEffect, useState } from 'react';

const useDevice = () => {
    const condition = () => window.innerWidth < 731;

    const [isMobile, setIsMobile] = useState(condition());

    const checkForDevice = () => setIsMobile(condition());

    useEffect(() => {
        checkForDevice();

        window.addEventListener('resize', checkForDevice);

        return () => {
            window.removeEventListener('resize', checkForDevice);
        };
    }, []); 

    return {
        isMobile,
        isDesktop: !isMobile,
    };
};

export default useDevice;
